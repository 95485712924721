<template>
  <div id="book" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdSelect
                rules="required"
                label="Class Group"
                v-model="book.classGroup.name"
              >
                <option v-for="classGroup in classGroups" :key="classGroup.id">
                  {{ classGroup.name }}
                </option>
              </SdSelect>

              <SdInput
                type="text"
                rules="required|min:2|max:30"
                label="Name"
                v-model="book.name"
              />

              <SdInput
                type="text"
                rules="required|min:2|max:30"
                label="Amount"
                v-model="book.amount"
              />

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import CLASS_GROUPS from '../../../graphql/class_group/ClassGroups.gql'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Book',
      skipQuery: true,
      book: {
        id: null,
        name: '',
        amount: '',
        classGroup: {},
      },
    }
  },
  apollo: {
    classGroups: {
      query: CLASS_GROUPS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    book: {
      query: gql`
        query BookQuery($id: ID!) {
          book(id: $id) {
            id
            name
            amount
            classGroup {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    'book.classGroup.name'(value) {
      if (value) {
        this.book.classGroupId = this.classGroups.filter(
          (classGroup) => classGroup.name === value
        )[0].id
      }
    },
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateBookQuery(
                $classGroupId: Int!
                $name: String!
                $amount: Float!
              ) {
                createBook(
                  input: {
                    classGroupId: $classGroupId
                    name: $name
                    amount: $amount
                  }
                ) {
                  book {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              classGroupId: parseInt(this.book.classGroupId),
              name: this.book.name,
              amount: parseFloat(this.book.amount),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createBook.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(`/school/${this.$route.params.school_id}/books`)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateBookQuery(
                $id: Int!
                $name: String!
                $amount: Float!
              ) {
                updateBook(input: { id: $id, name: $name, amount: $amount }) {
                  book {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.book.id),
              name: this.book.name,
              amount: parseFloat(this.book.amount),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateBook.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(`/school/${this.$route.params.school_id}/books`)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    this.$store.commit('setSubMenus', [
      {
        name: 'Books',
        route: `/school/${this.schoolId}/books`,
      },
    ])
  },
}
</script>
